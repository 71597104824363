import React from "react";
import { Col, Form, InputGroup, Row } from "react-bootstrap";
import { distrubutorOptions } from "./constant";
import { IClaimData } from "../../types/dashboard";
import { CustomSelect } from "../../components/Select";

interface Props {
  claimData: IClaimData;
  handleChangeClaimData: (key: string, value: number | string) => void;
  estimateTotalValue: (key: string, value: number | string) => void;
  reset: () => void;
}

export const PartInformation = ({
  handleChangeClaimData,
  claimData,
  estimateTotalValue,
  reset,
}: Props) => {
  const getPartAllowance = (cost: number) => {
    let allowance: number = 0.0;
    switch (true) {
      case cost > 0.0 && cost < 10.0:
        allowance = 5.0;
        break;
      case cost > 9.99 && cost < 25.0:
        allowance = 15.0;
        break;
      case cost > 24.99 && cost < 50.0:
        allowance = 20.0;
        break;
      case cost > 49.99 && cost < 75.0:
        allowance = 25.0;
        break;
      case cost > 74.99 && cost < 100.0:
        allowance = 30.0;
        break;
      case cost > 99.99 && cost < 125.0:
        allowance = 35.0;
        break;
      case cost > 124.99 && cost < 150.0:
        allowance = 40.0;
        break;
      case cost > 149.99:
        allowance = 45.0;
        break;
      default:
        allowance = 0.0;
    }
    return allowance;
  };

  return (
    <>
      <Row className="mt-5">
        <h3>Parts</h3>
        <hr />
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row} controlId="distributor1">
            <Form.Label column xs={6}>
              Distrubutor:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={distrubutorOptions}
                onChange={(value) =>
                  handleChangeClaimData("distributor1", value)
                }
                onFocus={(value) => estimateTotalValue("distributor1", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="description1">
            <Form.Label column xs={6}>
              Description:
            </Form.Label>
            <Col xs={6}>
              <Form.Control type="text" size="sm"></Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="price1">
            <Form.Label column xs={6}>
              Price:
            </Form.Label>
            <Col xs={6}>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  defaultValue="0.00"
                  aria-label="Amount"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const cost = parseFloat((event.target as any).value);
                    handleChangeClaimData(
                      "allowancePartOne",
                      getPartAllowance(isNaN(cost) ? 0.0 : cost)
                    );
                  }}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="allowance1">
            <Form.Label column xs={6}>
              Allowance:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${claimData?.allowancePartOne.toFixed(2) ?? "0.00"}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row} controlId="distributor2">
            <Form.Label column xs={6}>
              Distrubutor:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={distrubutorOptions}
                onChange={(value) =>
                  handleChangeClaimData("distributor2", value)
                }
                onFocus={(value) => estimateTotalValue("distributor2", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="description2">
            <Form.Label column xs={6}>
              Description:
            </Form.Label>
            <Col xs={6}>
              <Form.Control type="text" size="sm"></Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="price2">
            <Form.Label column xs={6}>
              Price:
            </Form.Label>
            <Col xs={6}>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount"
                  defaultValue="0.00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const cost = parseFloat((event.target as any).value);
                    handleChangeClaimData(
                      "allowancePartTwo",
                      getPartAllowance(isNaN(cost) ? 0.0 : cost)
                    );
                  }}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="allowance2">
            <Form.Label column xs={6}>
              Allowance:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${claimData?.allowancePartTwo.toFixed(2) ?? "0.00"}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row} controlId="distributor3">
            <Form.Label column xs={6}>
              Distrubutor:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={distrubutorOptions}
                onChange={(value) =>
                  handleChangeClaimData("distributor3", value)
                }
                onFocus={(value) => estimateTotalValue("distributor3", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="description3">
            <Form.Label column xs={6}>
              Description:
            </Form.Label>
            <Col xs={6}>
              <Form.Control type="text" size="sm"></Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="price3">
            <Form.Label column xs={6}>
              Price:
            </Form.Label>
            <Col xs={6}>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount"
                  defaultValue="0.00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const cost = parseFloat((event.target as any).value);
                    handleChangeClaimData(
                      "allowancePartThree",
                      getPartAllowance(isNaN(cost) ? 0.0 : cost)
                    );
                  }}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="allowance3">
            <Form.Label column xs={6}>
              Allowance:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${claimData?.allowancePartThree.toFixed(2) ?? "0.00"}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={3}>
          <Form.Group as={Row} controlId="distributor4">
            <Form.Label column xs={6}>
              Distrubutor:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={distrubutorOptions}
                onChange={(value) =>
                  handleChangeClaimData("distributor4", value)
                }
                onFocus={(value) => estimateTotalValue("distributor4", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="description4">
            <Form.Label column xs={6}>
              Description:
            </Form.Label>
            <Col xs={6}>
              <Form.Control type="text" size="sm"></Form.Control>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="price4">
            <Form.Label column xs={6}>
              Price:
            </Form.Label>
            <Col xs={6}>
              <InputGroup className="mb-3">
                <InputGroup.Text>$</InputGroup.Text>
                <Form.Control
                  aria-label="Amount"
                  defaultValue="0.00"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    const cost = parseFloat((event.target as any).value);
                    handleChangeClaimData(
                      "allowancePartFour",
                      getPartAllowance(isNaN(cost) ? 0.0 : cost)
                    );
                  }}
                />
              </InputGroup>
            </Col>
          </Form.Group>
        </Col>
        <Col xs={3}>
          <Form.Group as={Row} controlId="allowance4">
            <Form.Label column xs={6}>
              Allowance:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${claimData?.allowancePartFour.toFixed(2) ?? "0.00"}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
