import React from 'react'
import Select, { components, OptionProps } from 'react-select'
import { IOption } from '../types/dashboard'

interface Props {
  options: IOption[]
  onChange: (value: number | string) => void
  onFocus: (value: number | string) => void
  onLeave: () => void
}

export const CustomSelect = ({ options, onChange, onFocus, onLeave }: Props) => {
  const Option = (props: OptionProps<any>) => {
    return (
      // @ts-ignore
      <div onMouseEnter={() => onFocus(props.value)} onMouseLeave={() => onLeave()}>
        <components.Option {...props} />
      </div>
    );
  };
  return (
    <Select
      options={options}
      components={{ Option }}
      captureMenuScroll
      closeMenuOnSelect
      tabSelectsValue
      onChange={(option: IOption) => onChange(option.value)}
    />
  )
}
