import { IClaimData, IComponentData, IOption } from "../../types/dashboard";

export const componentList: IComponentData[] = [
  { serviceType: "AirHandler", hoursAllowed: 4.0 },
  { serviceType: "Bearing", hoursAllowed: 0.1 },
  { serviceType: "CasedCoil", hoursAllowed: 3.0 },
  { serviceType: "CondensatePump", hoursAllowed: 0.5 },
  { serviceType: "Condensing", hoursAllowed: 4.0 },
  { serviceType: "Damper", hoursAllowed: 1.0 },
  { serviceType: "DrainPan", hoursAllowed: 2.0 },
  { serviceType: "FanBlade", hoursAllowed: 1.0 },
  { serviceType: "FlowSwitch", hoursAllowed: 0.5 },
  { serviceType: "Furnace", hoursAllowed: 4.0 },
  { serviceType: "Louvre", hoursAllowed: 0.5 },
  { serviceType: "LouvreMotor", hoursAllowed: 1.0 },
  { serviceType: "Misc", hoursAllowed: 1.0 },
  { serviceType: "MotorMount", hoursAllowed: 1.0 },
  { serviceType: "Ofifice", hoursAllowed: 1.0 },
  { serviceType: "PTAC", hoursAllowed: 2.0 },
  { serviceType: "Pulley", hoursAllowed: 1.0 },
  { serviceType: "PulleyShaft", hoursAllowed: 1.0 },
  { serviceType: "Spring", hoursAllowed: 1.0 },
];

export const componentOptions: IOption[] = [
  { label: "Air Handler", value: "AirHandler" },
  { label: "Bearing", value: "Bearing" },
  { label: "Cased Coil", value: "CasedCoil" },
  { label: "Condensate Pump", value: "CondensatePump" },
  { label: "Condensing/PackageUnit", value: "Condensing" },
  { label: "Damper (sensor/zone control/ damper motor)", value: "Damper" },
  { label: "Drain pan - primary only", value: "DrainPan" },
  { label: "Fan blade/blower wheel", value: "FanBlade" },
  { label: "Flow Switch", value: "FlowSwitch" },
  { label: "Furnace", value: "Furnace" },
  { label: "Louver", value: "Louvre" },
  { label: "Louver Motor", value: "LouvreMotor" },
  { label: "Misc", value: "Misc" },
  { label: "Motor Mount", value: "MotorMount" },
  { label: "Ofifice", value: "Ofifice" },
  { label: "PTAC repair/replacement", value: "PTAC" },
  { label: "Pulley", value: "Pulley" },
  { label: "Pulley Shaft", value: "PulleyShaft" },
  { label: "Spring", value: "Spring" },
];

export const distrubutorOptions: IOption[] = [
  { label: "AC Supply", value: "ACSupply" },
  { label: "Trane", value: "Trane" },
  { label: "Carrier", value: "Carrier" },
  { label: "Lennox", value: "Lennox" },
  { label: "AllgierAir", value: "AllgierAir" },
  { label: "Ferguson", value: "Ferguson" },
  { label: "Johnstone", value: "Johnstone" },
  { label: "Other", value: "Other" },
];

export const laborRateOptions: IOption[] = [
  { label: "$85/HR", value: 85.0 },
  { label: "$125/HR", value: 125.0 },
];

export const serviceTypeOptions: IOption[] = [
  { label: "Mechanical", value: "Mechanical" },
  { label: "Electrical", value: "Electrical" },
  { label: "Plumbing", value: "Plumbing" },
  { label: "Combustion", value: "Combustion" },
];

export const refrigerantOptions: IOption[] = [
  { label: "1 lb", value: 1.0 },
  { label: "2 lb", value: 2.0 },
  { label: "3 lb", value: 3.0 },
  { label: "4 lb", value: 4.0 },
  { label: "5 lb", value: 5.0 },
  { label: "6 lb", value: 6.0 },
  { label: "7 lb", value: 7.0 },
  { label: "8 lb", value: 8.0 },
];

export const reclamationFeeOptions: IOption[] = [
  { label: "No", value: 0.0 },
  { label: "Yes", value: 55.0 },
];

export const InitialClaimData: IClaimData = {
  firstName: "",
  lastName: "",
  phone: "",
  contractNumber: "",
  email: "",
  address: "",
  city: "",
  state: "",
  postalCode: "",
  sku: "",
  endDt: "",
  startDt: "",
  laborHourCount: 0.0,
  laborRate: 0.0,
  refrigerantCost: 22.0,
  refrigerantUnit: 0.0,
  reclamationFee: 0.0,
  allowancePartOne: 0.0,
  allowancePartTwo: 0.0,
  allowancePartThree: 0.0,
  allowancePartFour: 0.0,
};

export const stateOptions = [
  { label: "AL", value: "AL" },
  { label: "AK", value: "AK" },
  { label: "AZ", value: "AZ" },
  { label: "AR", value: "AR" },
  { label: "CA", value: "CA" },
  { label: "CO", value: "CO" },
  { label: "CT", value: "CT" },
  { label: "DE", value: "DE" },
  { label: "DC", value: "DC" },
  { label: "FL", value: "FL" },
  { label: "GA", value: "GA" },
  { label: "HI", value: "HI" },
  { label: "ID", value: "ID" },
  { label: "IL", value: "IL" },
  { label: "IN", value: "IN" },
  { label: "IA", value: "IA" },
  { label: "KS", value: "KS" },
  { label: "KY", value: "KY" },
  { label: "LA", value: "LA" },
  { label: "ME", value: "ME" },
  { label: "MD", value: "MD" },
  { label: "MA", value: "MA" },
  { label: "MI", value: "MI" },
  { label: "MN", value: "MN" },
  { label: "MS", value: "MS" },
  { label: "MO", value: "MO" },
  { label: "MT", value: "MT" },
  { label: "NE", value: "NE" },
  { label: "NV", value: "NV" },
  { label: "NH", value: "NH" },
  { label: "NJ", value: "NJ" },
  { label: "NM", value: "NM" },
  { label: "NY", value: "NY" },
  { label: "NC", value: "NC" },
  { label: "ND", value: "ND" },
  { label: "OH", value: "OH" },
  { label: "OK", value: "OK" },
  { label: "OR", value: "OR" },
  { label: "PA", value: "PA" },
  { label: "RI", value: "RI" },
  { label: "SC", value: "SC" },
  { label: "SD", value: "SD" },
  { label: "TN", value: "TN" },
  { label: "TX", value: "TX" },
  { label: "UT", value: "UT" },
  { label: "VT", value: "VT" },
  { label: "VA", value: "VA" },
  { label: "WA", value: "WA" },
  { label: "WV", value: "WV" },
  { label: "WI", value: "WI" },
  { label: "WY", value: "WY" },
];
