export const User = {
  address: "9900 Corporate Campus Way",
  city: "Louisville",
  email: "janefoster@asgard.net",
  endDt: "12/31/2025",
  firstName: "Jane",
  lastName: "Foster",
  phone: "123-456-7890",
  postalCode: "40216",
  sku: "ARW-RETPR1B",
  startDt: "01/01/2020",
  state: "KY",
}