import React from 'react';
import "./App.css";
import { Dashboard } from './pages/Dashboard';

export default function App() {
  return (
    <>
      <Dashboard />
    </>
  )
}
