import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { CustomSelect } from "../../components/Select";
import {
  serviceTypeOptions,
  componentOptions,
  laborRateOptions,
  refrigerantOptions,
  reclamationFeeOptions,
} from "./constant";
import { IClaimData } from "../../types/dashboard";

interface Props {
  claimData: IClaimData;
  handleChangeClaimData: (key: string, value: number | string) => void;
  estimateTotalValue: (key: string, value: number | string) => void;
  reset: () => void;
}

export const MainInformation = ({
  claimData,
  handleChangeClaimData,
  estimateTotalValue,
  reset,
}: Props) => {
  return (
    <>
      <Row className="mt-5">
        <Col xs={4}>
          <Form.Group as={Row} controlId="laborRate">
            <Form.Label column xs={6}>
              Labor Rate:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={laborRateOptions}
                onChange={(value) => handleChangeClaimData("laborRate", value)}
                onFocus={(value) => estimateTotalValue("laborRate", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="serviceType">
            <Form.Label column xs={6}>
              Service Type:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={serviceTypeOptions}
                onChange={(value) =>
                  handleChangeClaimData("serviceType", value)
                }
                onFocus={(value) => estimateTotalValue("serviceType", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="component">
            <Form.Label column xs={6}>
              Components:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={componentOptions}
                onChange={(value) => handleChangeClaimData("component", value)}
                onFocus={(value) => estimateTotalValue("component", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={{ span: 4, offset: 4 }}>
          <Form.Group as={Row} controlId="refrigerantUnit">
            <Form.Label column xs={6}>
              Refrigerant:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={refrigerantOptions}
                onChange={(value) =>
                  handleChangeClaimData("refrigerantUnit", value)
                }
                onFocus={(value) =>
                  estimateTotalValue("refrigerantUnit", value)
                }
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
        <Col xs={4}>
          <Form.Group as={Row} controlId="reclamationFee">
            <Form.Label column xs={6}>
              Reclamation Fee:
            </Form.Label>
            <Col xs={6}>
              <CustomSelect
                options={reclamationFeeOptions}
                onChange={(value) =>
                  handleChangeClaimData("reclamationFee", value)
                }
                onFocus={(value) => estimateTotalValue("reclamationFee", value)}
                onLeave={reset}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
