import React from "react";
import { Col, Form, Row } from "react-bootstrap";
import { ITotalClaim } from "../../types/dashboard";

export const TotalInformation = ({
  totalClaim,
}: {
  totalClaim: ITotalClaim;
}) => {
  return (
    <>
      <Row>
        <Col xs={{ span: 3, offset: 9 }}>
          <Form.Group as={Row} controlId="totalLabor">
            <Form.Label column xs={6}>
              Total Labor:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${totalClaim.totalLabor?.toFixed(2)}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3, offset: 9 }}>
          <Form.Group as={Row} controlId="totalRefrigerant">
            <Form.Label column xs={6}>
              Refrigerant:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${totalClaim.totalRefrigerant?.toFixed(2)}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3, offset: 9 }}>
          <Form.Group as={Row} controlId="totalParts">
            <Form.Label column xs={6}>
              Total Parts:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${totalClaim.totalParts?.toFixed(2)}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3, offset: 9 }}>
          <Form.Group as={Row} controlId="tripFee">
            <Form.Label column xs={6}>
              Trip Fee:
            </Form.Label>
            <Col xs={6}>
              <Form.Control plaintext readOnly value="$65.00" size="sm" />
            </Col>
          </Form.Group>
        </Col>
      </Row>
      <Row>
        <Col xs={{ span: 3, offset: 9 }}>
          <Form.Group as={Row} controlId="total">
            <Form.Label column xs={6}>
              Total Claim:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={`$${totalClaim.total?.toFixed(2)}`}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  );
};
