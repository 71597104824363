import React from 'react'
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import Header from "../../components/header";
import { UserInformation } from './UserInformation';
import { PartInformation } from './PartInformation';
import { TotalInformation } from './TotalInformation';
import { MainInformation } from './MainInformation';
import { componentList, InitialClaimData } from './constant';
import { IClaimData, ITotalClaim } from '../../types/dashboard';
import { User } from '../../mocks/user';

export const Dashboard = () => {
  const [claimData, setClaimData] = React.useState<IClaimData>(InitialClaimData)
  const [totalClaim, setTotalClaim] = React.useState<ITotalClaim>({})

  const handleCostUpdate = () => {
    const {laborHourCount, laborRate, refrigerantCost, refrigerantUnit, reclamationFee, allowancePartOne, allowancePartTwo, allowancePartThree, allowancePartFour } = claimData

    let subtotal: number = 0.0
    let laborSubtotal: number = 0.0
    let partSubtotal: number = 0.0
    let refrigerantSubtotal: number = refrigerantCost

    laborSubtotal = laborHourCount * laborRate;

    partSubtotal = allowancePartOne + allowancePartTwo + allowancePartThree + allowancePartFour;

    let tripFee = 65.0

    refrigerantSubtotal = refrigerantCost * refrigerantUnit + reclamationFee;

    subtotal = laborSubtotal + partSubtotal + refrigerantSubtotal + tripFee;

    setTotalClaim({
      total: subtotal,
      totalLabor: laborSubtotal,
      totalParts: partSubtotal,
      totalRefrigerant: refrigerantSubtotal,
    });
  };

  React.useEffect(() => {
    handleCostUpdate()
    // eslint-disable-next-line
  }, [claimData])

  const handleChangeClaimData = (key: string, value: string | number) => {
    if (key === 'component') {
      const component = componentList.find(
        (component) => component.serviceType === value
      );
      if (component) {
        setClaimData({
          ...claimData,
          component: component.serviceType,
          laborHourCount: component.hoursAllowed,
        })
      }
    } else {
      setClaimData({
        ...claimData,
        [key]: value
      })
    }
  }

  const estimateTotalValue = (key: string, value: string | number) => {
    let delta = {...claimData}
    if (key === 'component') {
      const component = componentList.find(
        (component) => component.serviceType === value
      );
      if (component) {
        delta = {
          ...claimData,
          component: component.serviceType,
          laborHourCount: component.hoursAllowed,
        }
      }
    } else {
      delta = {
        ...claimData,
        [key]: value
      }
    }
    const {
      laborHourCount,
      laborRate,
      refrigerantCost,
      refrigerantUnit,
      reclamationFee,
      allowancePartOne,
      allowancePartTwo,
      allowancePartThree,
      allowancePartFour}
    = delta

    let subtotal: number = 0.0
    let laborSubtotal: number = 0.0
    let partSubtotal: number = 0.0
    let refrigerantSubtotal: number = refrigerantCost

    laborSubtotal = laborHourCount * laborRate;

    partSubtotal = allowancePartOne + allowancePartTwo + allowancePartThree + allowancePartFour;

    let tripFee = 65.0

    refrigerantSubtotal = refrigerantCost * refrigerantUnit + reclamationFee;

    subtotal = laborSubtotal + partSubtotal + refrigerantSubtotal + tripFee;

    setTotalClaim({
      total: subtotal,
      totalLabor: laborSubtotal,
      totalParts: partSubtotal,
      totalRefrigerant: refrigerantSubtotal,
    });
  }

  const handleSearch = () => {
    setClaimData({
      ...claimData,
      ...User
    })
  }

  return (
    <Form>
      <Container className="mt-3 mb-5">
        <Header title="File a Claim" />
        <hr />
        <UserInformation
          claimData={claimData}
          handleChangeClaimData={handleChangeClaimData}
          handleSearch={handleSearch}
        />
        <MainInformation
          claimData={claimData}
          handleChangeClaimData={handleChangeClaimData}
          estimateTotalValue={estimateTotalValue}
          reset={handleCostUpdate}
        />
        <PartInformation
          claimData={claimData}
          handleChangeClaimData={handleChangeClaimData}
          estimateTotalValue={estimateTotalValue}
          reset={handleCostUpdate}
        />
        <hr />
        <TotalInformation totalClaim={totalClaim} />
        <Row>
          <Col
            xs={{ span: 2, offset: 9 }}
            className="d-flex justify-content-end p-0"
          >
            <Button>Submit</Button>
          </Col>
        </Row>
      </Container>
    </Form>
  )
}