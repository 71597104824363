import React, { Component, Fragment } from "react";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import "bootstrap/dist/css/bootstrap.min.css";
//import auth from "../helpers/auth";

export default class Header extends Component<{ title: string }> {
  render() {
    return (
      <Fragment>
        <Row>
          <Col className="mt-3 mb-5 text-center">
            <Image src="/ComfortProtectionLogo.png" fluid />
          </Col>
        </Row>
        <Row>
          <Col className="text-center">
            <h1>{this.props.title}</h1>
          </Col>
        </Row>
      </Fragment>
    );
  }
}
