import React from 'react'
import { Col, Form, Row, InputGroup, Button } from "react-bootstrap";
import { IClaimData } from '../../types/dashboard';
import { stateOptions } from './constant';
import Select from 'react-select'

interface Props {
  claimData: IClaimData
  handleChangeClaimData: (key: string, value: number | string) => void
  handleSearch: () => void
}

export const UserInformation = ({ claimData, handleChangeClaimData,  handleSearch }: Props) => {
  return (
    <>
      <Row>
        <Col xs={5}>
          <Form.Label>Search by Contract:</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                handleChangeClaimData('contractNumber', event.target.value)
              }
              placeholder="Contract Number"
            />
            <Button
              variant="primary"
              onClick={handleSearch}
            >
              Search
            </Button>
          </InputGroup>
        </Col>
      </Row>
      <hr />
      <Row className="mt-3">
        <Col>
          <Form.Group as={Row} controlId="firstName">
            <Form.Label column xs={6}>
              First Name:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                autoComplete="off"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('firstName', event.target.value)
                }
                placeholder="First Name"
                value={claimData.firstName}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="lastName">
            <Form.Label column xs={6}>
              Last Name:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                type="text"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('lastName', event.target.value)
                }
                placeholder="Last Name"
                value={claimData.lastName}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="address">
            <Form.Label column xs={6}>
              Address:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                type="text"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('address', event.target.value)
                }
                placeholder="Street Address"
                value={claimData.address}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="city">
            <Form.Label column xs={6}>
              City:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                type="text"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('city', event.target.value)
                }
                placeholder="City"
                value={claimData.city}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="state">
            <Form.Label column xs={6}>
              State:
            </Form.Label>
            <Col xs={6}>
              <Select
                options={stateOptions}
                onChange={(option) => {
                  handleChangeClaimData('state', option?.value ?? "")
                }}
                value={stateOptions.find((option) => option.value === claimData.state)}
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="postalCode">
            <Form.Label column xs={6}>
              Postal Code:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                autoComplete="off"
                type="text"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('postalCode', event.target.value)
                }
                placeholder="Postal Code"
                value={claimData.postalCode}
                size="sm"
              />
              <Form.Control.Feedback type="invalid">
                Please provide a valid postal code.
              </Form.Control.Feedback>
            </Col>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group as={Row} controlId="email">
            <Form.Label column xs={6}>
              Email:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                type="email"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('email', event.target.value)
                }
                placeholder="Email"
                value={claimData.email}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="phone">
            <Form.Label column xs={6}>
              Phone:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                type="text"
                autoComplete="off"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                  handleChangeClaimData('phone', event.target.value)
                }
                placeholder="123-456-7890"
                value={claimData.phone}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="contractNumber">
            <Form.Label column xs={6}>
              Contract Number:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={claimData.contractNumber}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="startDt">
            <Form.Label column xs={6}>
              Start Date:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={claimData.startDt}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="endDt">
            <Form.Label column xs={6}>
              End Date:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={claimData.endDt}
                size="sm"
              />
            </Col>
          </Form.Group>
          <Form.Group className="mt-2" as={Row} controlId="sku">
            <Form.Label column xs={6}>
              SKU:
            </Form.Label>
            <Col xs={6}>
              <Form.Control
                plaintext
                readOnly
                value={claimData.sku}
                size="sm"
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>
    </>
  )
}